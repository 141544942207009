import React from 'react';
import SEO from '../components/helpers/seo';

import Layout from '../components/layout';
import Coming from '../components/rate/coming';
import Content from '../components/rate/content';
import Now from '../components/rate/now';
import useTranslation from '../hooks/useTranslation';

const Stopover = () => {
  const t = useTranslation();

  return (
    <Layout
      pages={{
        en: `/en/stopovers`,
        fr: `/fr/escales`,
      }}
    >
      <SEO title={t(`stopover.seo`)} />
      <section className="Rate">
        <section className="Rate__heading">
          <div className="container">
            <h1>{t(`stopover.title`)}</h1>
          </div>
        </section>
        {/*<Now />*/}
        <Content heading={t(`stopover.heading`)} />
        {/*<Coming />*/}
      </section>
    </Layout>
  );
};

export default Stopover;
